
import axios from 'axios'
import { computed, defineComponent, inject, ref } from 'vue'
import { useRouter } from 'vue-router'
import { AuthPlugin } from '@/auth'
import EchoInspectionUpload from '@/components/EchoInspectionUpload.vue'

export default defineComponent({
  name: 'Prediction',
  components: { EchoInspectionUpload },
  setup() {
    const router = useRouter()
    const inspection = ref<typeof EchoInspectionUpload>()
    const isRunning = ref(false)
    const predictedBms = ref()
    const predictedRank5Percentage = ref()
    const predictionUri = ref()
    const predictionId = ref()
    const searchTrapeziusThickness = ref()
    const searchTrapeziusSubcutaneousFatThickness = ref()
    const searchSubcutaneousFatThickness = ref()
    const searchLatissimusThickness = ref()
    const idTokenClaims = ref()

    const selectedUser = ref('')
    const users = [
      {
        value: 'auth0|65dd4fdbafc0548859adabb4',
        label: 'komine@mij-labo.co.jp',
      },
    ]

    const backendUrl: string | undefined = inject('BackendUrl')
    if (!backendUrl) {
      throw new Error('BackendUrl is not provided')
    }

    const auth: AuthPlugin | undefined = inject('Auth')
    if (!auth) {
      throw new Error('Auth plugin is not provided')
    }

    const getIdToken = async () => {
      idTokenClaims.value = await auth.getIdTokenClaims({})
      console.log(idTokenClaims)
    }

    const isAdminUser = computed(() => {
      const adminUserIds = [
        // dev
        'auth0|615377846c37af0069e0dda6', // komine@meatij.or.jp
        'auth0|62f116c5e2ced2deb7515179',
        'auth0|62f25f3c9ee45985bb75ae95',
        'auth0|62f25f6f9ee45985bb75ae98',
        'auth0|62f2ef22d419dc31ec70aa2c',
        // prd
        'auth0|613e7a532d94680069e59e8d', // komine@meatij.or.jp
        'auth0|61565785d885e00069659046', // 農業総合センター 畜産研究所
        'auth0|615657e66c37af0069e0ea65', // 畜産課
      ]
      return idTokenClaims.value && adminUserIds.includes(idTokenClaims.value.sub)
    })

    ;(async () => {
      await getIdToken()
    })()

    const predict = async () => {
      try {
        isRunning.value = true

        const data = new FormData()
        inspection.value?.uploadFile && data.append('inspection', inspection.value.uploadFile)

        const idToken = (await auth.getIdTokenClaims({}))?.__raw

        const response = await axios.post(`${backendUrl}/v1/prediction`, data, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${idToken}`,
          },
        })

        predictionId.value = response.data.id
        predictedBms.value = response.data.BMS
        predictedRank5Percentage.value = Math.round(response.data.CORN.probabilities[4] * 10) * 10
        predictedRank5Percentage.value = Math.max(10, Math.min(90, predictedRank5Percentage.value))
        predictionUri.value = response.headers['x-aimeat-predictionuri']
      } finally {
        isRunning.value = false
      }
    }

    const search = async () => {
      if (
        searchTrapeziusThickness.value != null &&
        searchTrapeziusSubcutaneousFatThickness.value != null &&
        searchSubcutaneousFatThickness.value != null &&
        searchLatissimusThickness.value != null
      ) {
        const idToken = (await auth.getIdTokenClaims({}))?.__raw
        const bms = predictedBms.value
        const tt = parseFloat(searchTrapeziusThickness.value)
        const tsft = parseFloat(searchTrapeziusSubcutaneousFatThickness.value)
        const sft = parseFloat(searchSubcutaneousFatThickness.value)
        const lt = parseFloat(searchLatissimusThickness.value)

        // 類似画像検索は必要ないが、検索データの記録のために実行する
        await axios.get(`${backendUrl}/v1/example`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
            'X-AIMEAT-PredictionUri': predictionUri.value,
          },
          params: { bms, tt, tsft, sft, lt },
        })
      }

      router.push(`/${predictionId.value}`)
    }

    return {
      inspection,
      isRunning,
      predictedBms,
      predictedRank5Proba: predictedRank5Percentage,
      searchTrapeziusThickness,
      searchTrapeziusSubcutaneousFatThickness,
      searchSubcutaneousFatThickness,
      searchLatissimusThickness,
      predict,
      search,
      selectedUser,
      users,
      isAdminUser,
    }
  },
})
