
import axios from 'axios'
import {format} from 'date-fns'
import {defineComponent, inject, reactive} from 'vue'
import {AuthPlugin} from '@/auth'
import {Download} from '@element-plus/icons-vue';

// todo: 定義箇所をまとめる
interface PredictionResult {
  BMS: number
  BMS_L2ALLG74: number
  BMS_L2ALLGL: number
  BMS_L2G74: number
  BMS_L2GL: number
  BMS_L3ALLG74: number
  BMS_L3ALLGL: number
  BMS_L3G74: number
  BMS_L3GL: number
}

interface UserResult {
  name: string
}

interface Prediction {
  id: number
  user: UserResult
  userId: string
  cattleId: string
  shotAt?: string
  shootingDate: string
  input: unknown
  result: PredictionResult
  createdAt?: string
  updatedAt?: string
}

export default defineComponent({
  name: 'List',
  setup() {
    const backendUrl: string | undefined = inject('BackendUrl')
    if (!backendUrl) {
      throw new Error('BackendUrl is not provided')
    }
    const auth: AuthPlugin | undefined = inject('Auth')
    if (!auth) {
      throw new Error('Auth plugin is not provided')
    }
    const isAdminUser = inject('IsAdminUser')
    if (isAdminUser === undefined) {
      throw new Error('IsAdminUser is not provided')
    }
    const predictions = reactive<Prediction[]>([])

    const formatDate = (date: string, pattern = 'yyyy-MM-dd HH:mm:ss') => {
      return format(new Date(date), pattern)
    }

    const getPredictions = async () => {
      const idToken = (await auth.getIdTokenClaims({}))?.__raw

      const response = await axios.get(`${backendUrl}/v1/prediction`, {
        headers: { Authorization: `Bearer ${idToken}` },
      })

      predictions.splice(0, predictions.length, ...response.data)
      predictions.forEach((prediction) => {
        if (prediction.updatedAt) {
          prediction.updatedAt = formatDate(prediction.updatedAt)
        }
        if (prediction.shotAt) {
          prediction.shotAt = formatDate(prediction.shotAt)
        } else {
          prediction.shotAt = formatDate(prediction.shootingDate, 'yyyy-MM-dd')
        }
      })
    }
    getPredictions()

    const downloadCsv = async () => {
      const filename = `result_${format(new Date(), 'yyyyMMddHHmmss')}.csv`
      let content = ['解析日時','ユーザー名','個体識別番号','撮影日時','推定BMS'].join(',') + '\n'
      predictions.forEach(pred => {
        const username = pred.user ? pred.user.name : '-'
        const line = [
          pred.updatedAt,
          username,
          pred.cattleId,
          pred.shotAt,
          pred.result.BMS,
        ].join(',') + '\n'
        content += line
      })
      content = '\ufeff' + content  // BOM付与(Excel文字化け対策)
      const blob = new Blob([content], { type: 'text/csv' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
    }

    return {
      predictions,
      downloadCsv,
      Download,
      isAdminUser,
    }
  },
})
