
import { defineComponent, computed, inject, ref, provide } from 'vue'
import { AuthPlugin } from '@/auth'

export default defineComponent({
  name: 'App',
  setup() {
    const idTokenClaims = ref()

    const auth: AuthPlugin | undefined = inject('Auth')
    if (!auth) {
      throw new Error('Auth plugin is not provided')
    }

    const handleMenuSelect = (key: string) => {
      if (key === '4') {
        auth.logout({ returnTo: process.env.VUE_APP_AUTH0_REDIRECT_URI })
      }
    }

    const getIdToken = async () => {
      idTokenClaims.value = await auth.getIdTokenClaims({})
      console.log(idTokenClaims)
    }

    const isAdminUser = computed(() => {
      const adminUserIds = [
        // dev
        'auth0|615377846c37af0069e0dda6', // komine@meatij.or.jp
        'auth0|62f116c5e2ced2deb7515179',
        'auth0|62f25f3c9ee45985bb75ae95',
        'auth0|62f25f6f9ee45985bb75ae98',
        'auth0|62f2ef22d419dc31ec70aa2c',
        // prd
        'auth0|613e7a532d94680069e59e8d', // komine@meatij.or.jp
        'auth0|61565785d885e00069659046', // 農業総合センター 畜産研究所
        'auth0|615657e66c37af0069e0ea65', // 畜産課
      ]
      return idTokenClaims.value && adminUserIds.includes(idTokenClaims.value.sub)
    })

    ;(async () => {
      await getIdToken()
    })()

    provide('IsAdminUser', isAdminUser);

    return {
      auth,
      isAdminUser,
      handleMenuSelect,
    }
  },
})
