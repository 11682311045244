/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createApp } from 'vue'
import './registerServiceWorker'

import {
  ElButton,
  ElCard,
  ElCol,
  ElDescriptions,
  ElDescriptionsItem,
  ElDialog,
  ElDivider,
  ElForm,
  ElFormItem,
  ElIcon,
  ElImage,
  ElInput,
  ElLoading,
  ElMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElOption,
  ElRow,
  ElSelect,
  ElTable,
  ElTableColumn,
  ElUpload,
} from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import print from 'vue3-print-nb'

import App from './App.vue'
import router from './router'
import store from './store'
import { Auth0 } from './auth'

import './styles/index.scss'

async function init() {
  const AuthPlugin = await Auth0.init({
    onRedirectCallback: (appState) => {
      router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
    },
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID!,
    domain: process.env.VUE_APP_AUTH0_DOMAIN!,
    redirectUri: process.env.VUE_APP_AUTH0_REDIRECT_URI!,
  })

  const app = createApp(App)
  app
    .provide('BackendUrl', process.env.VUE_APP_BACKEND_URL!.replace(/\/$/, ''))
    .provide('PredictionsUrl', process.env.VUE_APP_PREDICTIONS_URL!.replace(/\/$/, ''))
    .use(store)
    .use(router)
    .use(AuthPlugin)
    .use(print)
    .use(ElButton)
    .use(ElCard)
    .use(ElCol)
    .use(ElDescriptions)
    .use(ElDescriptionsItem)
    .use(ElDialog)
    .use(ElDivider)
    .use(ElForm)
    .use(ElFormItem)
    .use(ElIcon)
    .use(ElImage)
    .use(ElInput)
    .use(ElLoading)
    .use(ElMenu)
    .use(ElMenuItem)
    .use(ElMenuItemGroup)
    .use(ElOption)
    .use(ElRow)
    .use(ElSelect)
    .use(ElTable)
    .use(ElTableColumn)
    .use(ElUpload)
    .mount('#app')

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
}

init()
